import { Injectable } from '@angular/core';
import { Roles, RoleAccess } from '../models/roles.model';

@Injectable()
export class RoleService {
	public roles: Roles = {
		1: {
			role: 'Administrator',
			defaultRoute: '/sales/order/new',
			routeAccess: {
				dashboard: true,
				stock: true,
				user: true,
				support: true,
				profile: true,
				supplier: true,
				reports: true,
				stocktype: true,
				door: true,
				order: true,
				paint: true,
				sales: true,
				extra: true,
				client: true,
				accounts: true,
				notification: true,
				stockcolor: true,
				['purchase-order']: true,
				['sales-order']: true
			}
		},
		2: {
			role: 'Sales',
			defaultRoute: '/sales/order/new',
			routeAccess: {
				dashboard: false,
				stock: true,
				user: false,
				support: true,
				profile: true,
				supplier: true,
				reports: false,
				stocktype: false,
				door: true,
				order: true,
				paint: true,
				sales: true,
				extra: true,
				client: true,
				accounts: true,
				notification: true,
				stockcolor: true,
				['purchase-order']: true,
				['sales-order']: true
			}
		},
	};

	constructor() { }

	getAccess(role: number): RoleAccess {
		return <RoleAccess>this.roles[role];
	}
}
